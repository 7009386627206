import React from "react";
import { Fade } from "react-slideshow-image";
import {
  GamesData,
  // , gamesTab
} from "../../../assets/customData/Data";

import Button from "../form/Button";
import iosStore from "../../../assets/images/ios-store.png";
import playStore from "../../../assets/images/play-store.png";
import nextIcon from "../../../assets/images/white-next.svg";
import prevIcon from "../../../assets/images/prev-icon.svg";
import ReactReadMoreReadLess from "react-read-more-read-less";
import AOS from "aos";
export const FullImageSlider = ({
  images,
  contentSlider,
  scale,
  className,
  transitionDuration,
  duration,
  arrows,
}) => {
  const properties = {
    prevArrow: (
      <button>
        <img src={prevIcon} alt="..." />
      </button>
    ),
    nextArrow: (
      <button>
        <img src={nextIcon} alt="..." />
      </button>
    ),
  };
  return (
    // <Zoom
    //   scale={scale ? scale : []}
    //   indicators={false}
    //   duration={duration}
    //   transitionDuration={transitionDuration}
    //   // arrows={false}
    //   autoplay={true}
    //   cssclassName={className}
    // >
    //   {images?.map((slideImages, index) => (
    //     <div     key={index + 1} style={{ width: "100%" }}>
    //       <img
    //         style={{ objectFit: "cover", width: "100%" }}
    //         alt="Slide Image"
    //         src={slideImages}
    //       />
    //     </div>
    //   ))}

    //   {contentSlider?.map((item, index) => {
    //     // debugger;
    //     console.log(item, ">>>>");
    //     // <div className="cont-slides dark-bg"     key={index + 1}>
    //     //   <div className="row">
    //     //     <div className="col-md-6">
    //     //       <img alt="Slide Image" src={item.slideImage} />
    //     //     </div>
    //     //     <div className="col-md-6">
    //     //       <div className="heading">
    //     //         <h2>AR (AUGMENTED REALITY)</h2>
    //     //         <span className="line-bar"></span>
    //     //       </div>
    //     //       <div className="sub-heading">
    //     //         <h4>
    //     //           <span>Adventure | Mobile</span>
    //     //         </h4>
    //     //       </div>
    //     //       <p>
    //     //         From Virtual or Augmented Reality, to desktop browsers and
    //     //         mobile devices, a metaverse can use a range of devices to take
    //     //         its users to a different reality where they can build
    //     //         relationships, invest in assets, visit pla… Read More
    //     //       </p>
    //     //     </div>
    //     //   </div>
    //     // </div>;
    //   })}
    // </Zoom>
    <>
      <Fade
        {...properties}
        scale={scale ? scale : ""}
        indicators={false}
        duration={duration}
        transitionDuration={transitionDuration ? transitionDuration : ""}
        arrows={arrows}
        autoplay={true}
        cssclassName={className}
      >
        {images
          ? images?.map((slideImages, index) => (
              <div key={index + 1} style={{ width: "100%" }}>
                <img
                  style={{ objectFit: "cover", width: "100%" }}
                  alt="Slide Image"
                  src={slideImages}
                />
              </div>
            ))
          : GamesData.map((items, index) => {
              return (
                <div className="cont-slides dark-bg" key={index + 1}>
                  <div className="row">
                    <div
                      className="col-xl-6 cont-slides-img"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <img alt="Slide Image" src={items.slideImage} />
                    </div>
                    <div className="col-xl-6 cont-slides-cont  pt-65">
                      <div
                        className="d-block  cont-slides-cont-heading "
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                      >
                        <h2>{items.heading}</h2>

                        <span className="line-bar d-block ms-0"></span>
                      </div>

                      <div
                        className=" cont-slides-cont-sub-small-heading"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        data-aos-easing="ease-in-out"
                      >
                        <span>{items.subHeading}</span>
                      </div>
                      <div
                        className=" cont-slides-cont-discription"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                      >
                        <p>
                          <span>
                            <ReactReadMoreReadLess
                              charLimit={205}
                              readMoreText={"Read more "}
                              readLessText={"Read less "}
                            >
                              {items.content}
                            </ReactReadMoreReadLess>
                          </span>
                        </p>
                      </div>
                      <div className="cont-slides-cont-btns">
                        <ul className="d-flex ps-0 pt-5">
                          <li
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos="zoom-in"
                          >
                            <Button
                              link={items.iosAppUrl}
                              buttonIcon={iosStore}
                              className="bg-transparent p-0"
                            />
                          </li>
                          <li
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                          >
                            <Button
                              link={items.playStoreUrl}
                              buttonIcon={playStore}
                              className="bg-transparent p-0"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </Fade>
    </>
  );
};
AOS.init();
