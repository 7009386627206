import { useState } from "react";
import dropDown from "../../../assets/images/dropDown.svg";
export function DropDown({ dropDownData, placeholder, onChange, name, value }) {
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(placeholder);

  return (
    <div className="customm-dropdown ">
      <div className="dropdown">
        <div
          onClick={(e) => {
            setIsActive(!isActive);
          }}
          className={`dropdown-btn ${isActive ? "dropDown" : "dropUp"}`}
        >
          {/* <span className="btn-cont">{selected}</span> */}
          <span className="btn-cont">{value}</span>

          <span className="drp-img">
            <img src={dropDown} alt="drop-img" />
          </span>
        </div>
        <div
          className="dropdown-content"
          style={{ display: isActive ? "block" : "none" }}
        >
          {dropDownData?.map((item, index) => {
            return (
              <>
                <div
                  key={`key-${index + 1}`}
                  onClick={(e) => {
                    // setIsSelected(e.target.textContent);
                    setIsActive(!isActive);
                    onChange(name, item.dropdownTitle);
                  }}
                  className="item"
                >
                  {item.dropdownTitle}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
