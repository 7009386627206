import React from "react";
import { Tab, Tabs } from "react-bootstrap";
// import {
//   GamesData,
//   gamesArtTab,
//   gamesTab,
// } from "../../../assets/customData/Data";
// import { FullImageSlider } from "../sliders/FullImageSlider";

import ReactReadMoreReadLess from "react-read-more-read-less";
import iosStore from "../../../assets/images/ios-store.png";
import playStore from "../../../assets/images/play-store.png";
import Aos from "aos";
import Button from "../form/Button";
// import { Fade } from "react-slideshow-image";

// import nextIcon from "../../../assets/images/white-next.svg";
// import prevIcon from "../../../assets/images/prev-icon.svg";
export function CommomTab({
  data,
  key,
  setKey,
  // images,
  // contentSlider,
  // scale,
  // className,
  // transitionDuration,
  // duration,
  // arrows,
}) {
  // const properties = {
  //   prevArrow: (
  //     <button>
  //       <img src={prevIcon} />
  //     </button>
  //   ),
  //   nextArrow: (
  //     <button>
  //       <img src={nextIcon} />
  //     </button>
  //   ),
  // };
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
      defaultActiveKey={key}
    >
      {data?.map((items, index) => {
        return (
          <Tab eventKey={items.title} title={items.title} key={index + 1}>
            <p
              className="common-text py-5"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              {items.demo}
            </p>
            <div className="content-sec bg-white">
              {items.id > 6 ? (
                <div
                  className="cont-slides overflow-hidden dark-bg"
                  key={index + 1}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <div className=" cont-slides-cont">
                        <div className="d-block  cont-slides-cont-heading ">
                          <h2
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                          >
                            {items.heading}
                          </h2>
                          <span
                            className="line-bar d-block ms-0"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                          ></span>
                        </div>
                        <div className=" cont-slides-cont-discription">
                          <p
                            className="common-text py-5"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                          >
                            {items.content}
                          </p>
                        </div>
                      </div>
                      <div className=" cont-slides-cont">
                        <div className="d-block  sub-heading ">
                          <h2
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-easing="ease-in-out"
                          >
                            {items.heading01}
                          </h2>
                        </div>
                        <div className=" cont-slides-cont-discription">
                          <p
                            className="common-text pt-1"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                          >
                            {items.content01}
                          </p>
                        </div>
                      </div>
                      <div className=" cont-slides-cont pt-3">
                        <div className="d-block  sub-heading ">
                          <h2
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                          >
                            {items.heading02}
                          </h2>
                        </div>
                        <div className=" cont-slides-cont-discription">
                          <p
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            className="common-text pt-1"
                          >
                            {items.content02}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-6 cont-slides-img "
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <img alt="Slide Image" src={items.slideImage} />
                    </div>
                  </div>
                </div>
              ) : (
                // <Fade
                //   {...properties}
                //   scale={scale ? scale : ""}
                //   indicators={false}
                //   duration={duration}
                //   transitionDuration={
                //     transitionDuration ? transitionDuration : ""
                //   }
                //   arrows={arrows}
                //   autoplay={true}
                //   cssclassName={className}
                // >
                //   {/* {
                //       items?.sliderData?.length &&
                //     items?.sliderData((elem) => {
                //       return ( */}
                <div className="cont-slides dark-bg" key={index + 1}>
                  <div className="row">
                    <div
                      className="col-xl-6 cont-slides-img"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      <img alt="Slide Image" src={items.slideImage} />
                    </div>
                    <div className="col-xl-6 cont-slides-cont  pt-65">
                      <div
                        className="d-block  cont-slides-cont-heading "
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                      >
                        <h2>{items.heading}</h2>

                        <span className="line-bar d-block ms-0"></span>
                      </div>

                      <div
                        className=" cont-slides-cont-sub-small-heading"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        data-aos-easing="ease-in-out"
                      >
                        <span>{items.subHeading}</span>
                      </div>
                      <div
                        className=" cont-slides-cont-discription"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                      >
                        <p>
                          <span>
                            <ReactReadMoreReadLess
                              charLimit={205}
                              readMoreText={"Read more "}
                              readLessText={"Read less "}
                            >
                              {items.content}
                            </ReactReadMoreReadLess>
                          </span>
                        </p>
                      </div>
                      <div className="cont-slides-cont-btns">
                        <ul className="d-flex ps-0 pt-5">
                          {items.iosAppUrl ? (
                            <li
                              data-aos-duration="1000"
                              data-aos-easing="ease-in-out"
                              data-aos="zoom-in"
                            >
                              <Button
                                link={items.iosAppUrl}
                                buttonIcon={iosStore}
                                className="bg-transparent p-0"
                              />
                            </li>
                          ) : (
                            ""
                          )}
                          {items.playStoreUrl ? (
                            <li
                              data-aos="zoom-in"
                              data-aos-duration="1000"
                              data-aos-easing="ease-in-out"
                            >
                              <Button
                                link={items.playStoreUrl}
                                buttonIcon={playStore}
                                className="bg-transparent p-0"
                              />
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                //   {/* //   );
                //     // })} */}
                // </Fade>

                // <FullImageSlider
                //   contentSlider={gamesTab.gamesTab}
                //   scale={1}
                //   arrows={true}
                //   duration={6000}
                //   transitionDuration={1000}
                // />
              )}
            </div>
          </Tab>
        );
      })}
    </Tabs>
  );
}
Aos.init();
