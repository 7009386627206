import React, { useState } from "react";
import ScrollSpy from "react-ui-scrollspy";
import Header from "../portalLayout/Header";
export default function Dummy() {
  return (
    <>
      <Header />
      <ScrollSpy scrollThrottle={100} useBoxMethod={false}>
        {/* <Center id="orange" backgroundColor={"orange"}>
          <h1>Orange</h1>
        </Center>
        <Center id="brown" backgroundColor={"brown"}>
          <h1>Brown</h1>
        </Center>
        <Center id="blue" backgroundColor={"blue"}>
          <h1>Blue</h1>
        </Center>
        <Center id="green" backgroundColor={"green"}>
          <h1>Green</h1>
        </Center> */}
      </ScrollSpy>
    </>
  );
}
