import React from "react";
import { Link } from "react-router-dom";
export default function Button({
  type,
  className,
  id,
  onClick,
  link,
  title,
  data,
  disable,
  buttonIcon,
  target_blank,
}) {
  return (
    <Link
      to={link ? link : ""}
      // state={data}
      type={type}
      id={id}
      onClick={onClick}
      className={`btn theme-btn ${className ? className : ""}`}
      disabled={disable}
    >
      {title ? title : ""}
      {buttonIcon ? <img src={buttonIcon} /> : ""}
    </Link>
  );
}
