import logo from "./logo.svg";
import "./App.scss";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import Home from "./component/landingPage/Home";
import Dummy from "./component/landingPage/Dummy";
function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dummy" element={<Dummy />} />
      </Routes>
    </HashRouter>
  );
}
export default App;
