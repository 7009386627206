import React, { useState } from "react";
import Header from "../portalLayout/Header";

// images
import slide01 from "../../assets/images/try-slider-small.png";
import slide02 from "../../assets/images/try-slider-small3.png";
import slide05 from "../../assets/images/try-slider-small3.png";
import slide03 from "../../assets/images/me-about2.jpeg";
import slide04 from "../../assets/images/me-about2.jpeg";
import logo01 from "../../assets/images/logo-1.png";
import logo02 from "../../assets/images/logo-2.png";
import logo03 from "../../assets/images/logo-3.png";
import logo04 from "../../assets/images/logo-4.png";
import meta01 from "../../assets/images/meta-img.png";
import meta02 from "../../assets/images/meta-img02.png";
import phone from "../../assets/images/phone-icon.svg";
import email from "../../assets/images/email-icon.svg";
import website from "../../assets/images/website-icon.svg";
import address from "../../assets/images/location-icon.svg";
import blueRIghtArrow from "../../assets/images/arrow-right.svg";
import { FullImageSlider } from "../utils/sliders/FullImageSlider";
import { CommomTab } from "../utils/tabs/CommomTab";
import {
  GamesData,
  gamesTab,
  gamesArtTab,
  // gamesArtTabData,
  NftDevData,
  multicards,
  gallery,
  hiringSlides,
} from "../../assets/customData/Data";
import { Card } from "../utils/content/Card";
import { Multi_item_slider } from "../utils/sliders/Multi_item_slider";
import { MessageDrop } from "../utils/form/messageForm/MessageDrop";
import Footer from "../portalLayout/Footer";
import CommonModal from "../utils/modal/CommonModal";
import { Link } from "react-router-dom";
import { ApplyJob } from "../utils/form/jobApply/ApplyJob";
import AOS from "aos";
import { Loader } from "../utils/loader/Loader";
// import { data } from "../../assets/customData/Data";

export default function Home(galleryData, index) {
  const [key, setKey] = useState("");
  const [key1, setKey1] = useState("");
  const [show, setShow] = useState(false);
  const jobDetailsModalOpen = () => setShow(true);
  const jobDetailsModalClose = () => setShow(false);
  const [jobApplyshow, jobApplysetShow] = useState(false);
  const jobApplyOpen = () => jobApplysetShow(true);
  const jobApplyClose = () => jobApplysetShow(false);
  const [jobAppliedsetShow] = useState(false);
  const jobAppliedOpen = () => jobAppliedsetShow(true);
  // const jobAppliedClose = () => jobAppliedsetShow(false);
  // slider data
  const bannerSlides = [slide01, slide02, slide05];
  const aboutSlider = [slide03, slide04];
  const metaDevSlide = [meta01, meta02];

  const responsiveSettings = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  const responsiveCardSettings = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  const [hideShow, setHideShow] = useState(false);
  const [loader, setLoader] = useState(true);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const gotoServices = (id, navId) => {
    // debugger;
    const element = document.getElementById(id);
    const navEement = document.getElementById(navId);
    element.scrollIntoView();
    // debugger;
    navEement.classList.add("active");
    // navEement.classList.remove("active");
  };
  return (
    <>
      <div id="wrapper">
        {/* <Loader /> */}
        {loader && <Loader />}
        <Header
          setHideShow={setHideShow}
          hideShow={hideShow}
          gotoServices={gotoServices}
        />
        {/* <div className={`xeemu-home ${hideShow ? "navigationShow " : ""}  `}> */}
        <div className="xeemu-home">
          {/* Main Slider Section Starts Here  */}
          <section className="main-slider bg-dark">
            <div className="container position-relative">
              <div className="carousel-content ">
                <h1
                  className="font-raleway slide-overlay-animation "
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-sine"
                >
                  BEST <span className="primary-color">Shisha&nbsp;</span>Lounge
                  IN&nbsp;
                  <span className="primary-color">AUCKLAND</span>
                </h1>

                {/* <p
                  data-aos="zoom-in"
                  data-aos-duration="1400"
                  data-aos-easing="ease-in-sine"
                >
                  We always stay one step ahead in the market from all
                  competitors. Continuous innovation and rigorous testing along
                  with creative and strong project management, intuitive
                  application design
                </p> */}
                <div>
                  <button
                    className="btn  theme-btn"
                    type="submit"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-sine"
                  >
                    Visit Today
                  </button>
                </div>
              </div>
            </div>
            <FullImageSlider
              images={bannerSlides}
              scale={1}
              arrows={false}
              duration={2000}
              transitionDuration={2000}
              className="bannerSlider bg-dark"
            />
          </section>
          {/* Main Slider Section Ends Here  */}
          {/* -- About Section Starts Here - */}
          <section className="xeemu-about py-113" id="about">
            <div className="container">
              {/* <!-- Heading Starts Here --> */}
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>A</span>
                    <span>B</span>
                    <span>O</span>
                    <span>U</span>
                    <span>T</span> &nbsp;
                    <span className="drak-blue">U</span>
                    <span className="drak-blue">S</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
              {/* <!-- Heading Ends Here --> */}
              <div className="row">
                <div className="col-xl-6">
                  <div className="about-dis">
                    <p
                      className="common-text"
                      data-aos="zoom-in"
                      data-aos-duration="600"
                      data-aos-easing="ease-in-sine"
                    >
                      <span>
                        <h1>Mastani Shisha Lounge Auckland</h1> <br />
                        <br />
                        Perfect place for hangout with friends in Auckland
                        Mastani Shisha Lounge gives you ultimate experience of
                        entertainment and gatherings which music and environment
                        is so good to enhance your expereince. Here at Mastani
                        Shisha Lounge you can plan causal meetings, parties and
                        memorable reception. Unlimited fun along with live
                        performances.
                      </span>
                    </p>
                    <p
                      className="common-text"
                      data-aos="zoom-in"
                      data-aos-duration="600"
                      data-aos-easing="ease-in-sine"
                    >
                      <span>
                        Your search for great outstanding all fancy bar ends
                        here at Mastani shisha Lounge and ambience here is very
                        great and this space has royal touch everywhere.
                      </span>
                    </p>
                  </div>
                  <ul className="d-flex ps-0">
                    <li data-aos="zoom-in" data-aos-duration="800">
                      <img src={logo01} alt="Mastani Shisha Lounge" />
                    </li>
                    <li data-aos="zoom-in" data-aos-duration="1000">
                      <img src={logo02} alt="Best Lounge in Auckland" />
                    </li>
                    <li data-aos="zoom-in" data-aos-duration="1200">
                      <img src={logo03} alt="Shisha in Auckland" />
                    </li>
                    <li data-aos="zoom-in" data-aos-duration="1400">
                      <img src={logo04} alt="Mastani Lounge" />
                    </li>
                  </ul>
                  {/* <Multi_item_slider slides={multiLogoSlides} /> */}
                </div>
                <div
                  className="col-xl-6 about-slider"
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="2000"
                >
                  <FullImageSlider
                    images={aboutSlider}
                    scale={1}
                    arrows={false}
                    duration={2000}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <!-- About Section Ends Here --> */}
          {/* <!-- Our Games Section Starts Here --> */}
          {/* <section className="our-games py-113" id="games">
            <div className="container">
         
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>O</span>
                    <span>U</span>
                    <span>R</span> &nbsp;
                    <span className="drak-blue">G</span>
                    <span className="drak-blue">A</span>
                    <span className="drak-blue">M</span>
                    <span className="drak-blue">E</span>
                    <span className="drak-blue">S</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
            
              <CommomTab
                setKey={setKey}
                Key={key}
                data={gamesTab}
                isGameTab={true}
                tabData={GamesData}
              />
            </div>
          </section> */}
          {/* <!-- Our Games Section Ends Here --> */}
          {/* <!--  Games Art --> */}
          {/* <section className="our-games py-113" id="gameArt">
            <div className="container">
            
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>G</span>
                    <span>A</span>
                    <span>M</span>
                    <span>E</span> &nbsp;
                    <span className="drak-blue">A</span>
                    <span className="drak-blue">R</span>
                    <span className="drak-blue">T</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
     
              <CommomTab
                setKey={setKey1}
                Key={key1}
                data={gamesArtTab}
                isGameTab={false}
              
              />
            </div>
          </section> */}
          {/* <!--  end   Games Art --> */}
          {/* <!-- NFT Dev Section Starts Here --> */}
          <section className="nft-dev py-113" id="NFT">
            <div className="container">
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>O</span>
                    <span>U</span>
                    <span>R</span> &nbsp;
                    <span className="drak-blue">M</span>
                    <span className="drak-blue">E</span>
                    <span className="drak-blue">N</span>
                    <span className="drak-blue">U</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>

              <p
                className="common-text heading-cont"
                data-aos="zoom-out"
                data-aos-duration="1200"
                data-aos-easing="ease-in-sine"
              >
                Here at Mastani Shisha Lounge we offer a wide range of hookah
                pipes and flavoured shisha products from quality brands to make
                your expereince pleasant with us. We also offer a wide range of
                black, fruit, green, and floral teas and Proper Pizzas, along
                with other quality products.
              </p>
            </div>
            <div className="container">
              <div className="row">
                <Card commonCard={NftDevData} />
              </div>
            </div>
          </section>
          {/* <!-- NFT Dev Section Ends Here --> */}
          {/* <!-- Metaverse Section Starts Here --> */}
          {/* <section className="metaverse py-113" id="metaverse">
            <div className="container">
          
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>M</span>
                    <span>E</span>
                    <span>T</span>
                    <span>A</span>
                    <span>V</span>
                    <span>E</span>
                    <span>R</span>
                    <span>S</span>
                    <span>E</span>
                    &nbsp;
                    <span className="drak-blue">D</span>
                    <span className="drak-blue">E</span>
                    <span className="drak-blue">V</span>
                    <span className="drak-blue">E</span>
                    <span className="drak-blue">L</span>
                    <span className="drak-blue">O</span>
                    <span className="drak-blue">P</span>
                    <span className="drak-blue">M</span>
                    <span className="drak-blue">E</span>
                    <span className="drak-blue">N</span>
                    <span className="drak-blue">T</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
    
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 about-slider">
                    <FullImageSlider
                      images={metaDevSlide}
                      scale={1}
                      arrows={false}
                      duration={2000}
                      transitionDuration={2000}
                    />
                  </div>
                  <div className="col-xl-6 cont-sec">
                    <h3
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      FUTURE WITH METAVERSE
                    </h3>
                    <p
                      className="common-text pt-3"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry’s
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                    </p>
                    <div className="mt-5 pt-md-5">
                      <h3
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                      >
                        INGENIOUS NEW TECHNOLOGY
                      </h3>
                      <p
                        className="common-text pt-3"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                      >
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry’s standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting,
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- Metaverse Section Ends Here --> */}
          {/* <!-- Our Team Section Starts Here --> */}
          {/* <section className="our-team py-113" id="team">
            <div className="container">
             
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>O</span>
                    <span>U</span>
                    <span>R</span>
                    &nbsp;
                    <span className="drak-blue">T</span>
                    <span className="drak-blue">E</span>
                    <span className="drak-blue">A</span>
                    <span className="drak-blue">M</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
            
              <p
                className="common-text heading-cont mb-4"
                data-aos="zoom-out"
                data-aos-duration="1200"
                data-aos-easing="ease-in-sine"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
        
              <Multi_item_slider
                slidesToScroll={1}
                slidesToShow={5}
                indicators={true}
                slides={multicards}
                responsive={responsiveSettings}
              />
            </div>
          </section> */}
          {/* <!-- Our Team Section Ends Here --> */}
          {/* <!-- Our Studio Section Starts Here --> */}
          <section className="our-studio py-113" id="studio">
            <div className="container">
              {/* <!-- Heading Starts Here --> */}
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>O</span>
                    <span>U</span>
                    <span>R</span> &nbsp;
                    <span className="drak-blue">G</span>
                    <span className="drak-blue">A</span>
                    <span className="drak-blue">L</span>
                    <span className="drak-blue">L</span>
                    <span className="drak-blue">E</span>
                    <span className="drak-blue">R</span>
                    <span className="drak-blue">Y</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
              {/* <!-- Heading Ends Here --> */}
              <p
                className="common-text heading-cont"
                data-aos="zoom-out"
                data-aos-duration="1200"
                data-aos-easing="ease-in-sine"
              >
                It’s not all about shisha with the large space regular DJ nights
                and comedy night there’s all ways something that’s happen at
                Mastani Lounge. You can hire out the entire venue for all your
                events, not just your ones that shisha will be at.
              </p>
              <div className="row">
                {gallery?.map((galleryData, index) => (
                  <div className="col-md-4" key={index + 1}>
                    <div className="gallery-img">
                      <img
                        src={galleryData.image}
                        alt="Studio-1"
                        data-aos="zoom-out"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-sine"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* <!-- Our Studio Section Ends Here --> */}
          {/* <!-- Hiring Section Starts Here --> */}
          {/* <section className="hiring py-113" id="career">
            <div className="container">
          
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span> W</span>
                    <span> E'</span> <span>R</span>
                    <span>E</span>
                    &nbsp;
                    <span className="drak-blue">H</span>
                    <span className="drak-blue">I</span>
                    <span className="drak-blue">R</span>
                    <span className="drak-blue">I</span>
                    <span className="drak-blue">N</span>
                    <span className="drak-blue">G</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
         
              <h3
                className="small-heading"
                data-aos="zoom-out"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
              >
                JOB OPENINGS
              </h3>
              <p
                className="common-text heading-cont"
                data-aos="zoom-out"
                data-aos-duration="1200"
                data-aos-easing="ease-in-sine"
              >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Officia ratione ad ducimus modi consectetur velit, esse id
                voluptatem laborum magnam cum praesentium illo, pariatur fuga
                dignissimos est quam impedit? Unde deleniti adipisci maxime
                laudantium numquam ducimus accusantium minus temporibus.
              </p>
              <div className="hiring-slides">
                <Multi_item_slider
                  slidesToScroll={1}
                  slidesToShow={3}
                  indicators={true}
                  responsive={responsiveCardSettings}
                  slides={hiringSlides}
                  onClick={jobDetailsModalOpen}
                />
              </div>
            </div>
          </section> */}
          {/* <!-- Hiring Section Ends Here --> */}
          {/* <!-- Contact Section Starts Here --> */}
          <section className="contact py-113" id="contact">
            <div className="container">
              {/* <!-- Heading Starts Here --> */}
              <div className="heading">
                <h2 className=" animation-waviy">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                  >
                    <span>B </span>
                    <span>O</span>
                    <span>O</span>
                    <span>K</span>
                    {/* <span>N </span> */}
                    &nbsp;
                    <span className="drak-blue">P</span>
                    <span className="drak-blue">A</span>
                    <span className="drak-blue">R</span>
                    <span className="drak-blue">T</span>
                    <span className="drak-blue">Y</span>
                  </p>
                </h2>
                <span className="line-bar"></span>
              </div>
              {/* <!-- Heading Ends Here --> */}
              <h2
                className="common-text heading-cont"
                data-aos="zoom-in"
                data-aos-duration="1200"
                data-aos-easing="ease-in-sine"
              >
                Book Mastani Shisha Lounge Now in Auckland
              </h2>
              <div className="container">
                <div className="row">
                  <div className="col-xl-7">
                    <h3
                      className="font-semi-bold-raleway small-heading"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-sine"
                    >
                      PARTY DETAILS
                    </h3>

                    <MessageDrop />
                  </div>
                  <div className="col-xl-5 details">
                    <h3
                      className="font-semi-bold-raleway  small-heading"
                      data-aos="zoom-in-left"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-sine"
                    >
                      OUR DETAILS
                    </h3>
                    <div className="addr-info-area">
                      <ul>
                        <li>
                          <span className="addr-icon">
                            <img
                              src={phone}
                              alt="phone-icon"
                              data-aos="zoom-out"
                              data-aos-duration="500"
                              data-aos-easing="ease-in-sine"
                            />
                            Phone:
                          </span>

                          <span
                            className="addr-info"
                            data-aos="zoom-out"
                            data-aos-duration="600"
                            data-aos-easing="ease-in-sine"
                          >
                            <a href="tel:+64 22 069 8915">+64 22 069 8915</a>
                          </span>
                        </li>
                        <li>
                          <span className="addr-icon">
                            <img
                              src={email}
                              alt="email-icon"
                              data-aos="zoom-out"
                              data-aos-duration="700"
                              data-aos-easing="ease-in-sine"
                            />
                            <span
                              data-aos="zoom-out"
                              data-aos-duration="800"
                              data-aos-easing="ease-in-sine"
                            >
                              Email:
                            </span>
                          </span>

                          <span
                            className="addr-info"
                            data-aos="zoom-out"
                            data-aos-duration="800"
                            data-aos-easing="ease-in-sine"
                          >
                            <span
                              data-aos="zoom-out"
                              data-aos-duration="900"
                              data-aos-easing="ease-in-sine"
                            >
                              <a href="mailto: info@mastani.co.nz">
                                info@mastani.co.nz
                              </a>
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className="addr-icon">
                            <img
                              src={website}
                              alt="website-icon"
                              data-aos="zoom-out"
                              data-aos-duration="900"
                              data-aos-easing="ease-in-sine"
                            />
                            <span
                              data-aos="zoom-out"
                              data-aos-duration="1000"
                              data-aos-easing="ease-in-sine"
                            >
                              Website:
                            </span>
                          </span>

                          <span
                            className="addr-info"
                            data-aos="zoom-out"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-sine"
                          >
                            www.mastani.co.nz
                          </span>
                        </li>
                        <li>
                          <span className="addr-icon">
                            <img
                              src={address}
                              alt="location-icon"
                              data-aos="zoom-out"
                              data-aos-duration="1100"
                              data-aos-easing="ease-in-sine"
                            />
                            <span
                              data-aos="zoom-out"
                              data-aos-duration="1200"
                              data-aos-easing="ease-in-sine"
                            >
                              Address:
                            </span>
                          </span>

                          <span
                            className="addr-info"
                            data-aos="zoom-out"
                            data-aos-duration="1200"
                            data-aos-easing="ease-in-sine"
                          >
                            302 Te Irirangi Drive, Flat Bush, Auckland 2013, New
                            Zealand
                          </span>
                        </li>
                      </ul>
                      <div
                        className="location-area"
                        data-aos="zoom-out"
                        data-aos-duration="1200"
                        data-aos-easing="ease-in-sine"
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3187.9688920804865!2d174.8996255152945!3d-36.96279977991541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4d4e2036155f%3A0x815cb36ec67b0ca1!2sMastani%20shisha%20lounge!5e0!3m2!1sen!2sin!4v1667500515970!5m2!1sen!2sin"
                          title="map"
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>

                        {/* <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.1339505627625!2d76.6896935155477!3d30.714634481643355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee543f626e9f%3A0x225f86fc1b279fc9!2sPlot%20D-199%2C%20Phase%2C%208B%2C%20Phase%208B%2C%20Industrial%20Area%2C%20Sector%2074%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1662640887573!5m2!1sen!2sin"
                          title="map"
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Contact Section Ends Here --> */}
          {/* <!-- Footer Section Starts Here --> */}
          <Footer />
          {/* <!-- Footer Section Ends Here --> */}
        </div>
        {/* modals  */}
        {/* job details */}
        <CommonModal
          className="job-modal"
          heading={
            <h2 className="modal-heading small-heading">LEAD PROGRAMMER</h2>
          }
          show={show}
          close={jobDetailsModalClose}
          body={
            <div className="common_modal">
              <div className="modal-content">
                <h4 className="modal-sub-heading">THE IDEAL CANDIDATE: </h4>
                <p className="common-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever
                </p>
                <ul className="sub-details skills-req">
                  <li>C++ </li>
                  <li>Dream Weaver</li>
                  <li>Unreal Engine</li>
                </ul>
              </div>
              <div className="modal-content ">
                <h4 className="modal-sub-heading">REQUIREMENTS:</h4>

                <ul className="sub-details">
                  <li className="common-text">
                    <span>
                      <img src={blueRIghtArrow} alt="..." />
                    </span>
                    B.Sc. in Computer Science and/or Mathematics
                  </li>
                  <li className="common-text">
                    <span>
                      <img src={blueRIghtArrow} alt="..." />
                    </span>
                    Proficient with c++ and object-oriented programming
                  </li>
                  <li className="common-text">
                    <span>
                      <img src={blueRIghtArrow} alt="..." />
                    </span>
                    Development experience in the games industry a plus.
                  </li>
                  <li className="common-text">
                    <span>
                      <img src={blueRIghtArrow} alt="..." />
                    </span>
                    Strong communication and organizational skills
                  </li>
                </ul>
              </div>

              <div className="modal-content">
                <h4 className="modal-sub-heading">HOW TO APPLY:</h4>
                <p className="common-text">
                  If you think you have what it takes to join our team you an
                  apply here <Link to="">info@xeemu.com</Link>
                </p>
              </div>
            </div>
          }
          footer={
            <div className="job-footer">
              <button
                className="btn theme-btn btn-dark"
                type="cancel"
                onClick={jobDetailsModalClose}
              >
                CLOSE
              </button>
              <button
                className="btn theme-btn"
                type="submit"
                onClick={() => {
                  jobApplyOpen();
                  jobDetailsModalClose();
                }}
              >
                APPLY
              </button>
            </div>
          }
        />
        {/* job apply */}
        <CommonModal
          className="job-modal"
          heading={
            <h2 className="modal-heading small-heading">
              SUCCESS AND ACHIEVEMENT
            </h2>
          }
          show={jobApplyshow}
          close={jobApplyClose}
          body={
            <div className="common_modal">
              <ApplyJob
                jobApplyClose={jobApplyClose}
                jobAppliedsetShow={jobAppliedOpen}
              />
            </div>
          }
        />
        {/* thank you modal  */}
        {/* <CommonModal
          className="thank-modal"
          show={jobAppliedshow}
          close={jobAppliedClose}
          body={
            <div className="common_modal text-center">
              <span className="close-btn float-end " role="button">
                <img src={crossIcon} alt="..." onClick={jobAppliedClose} />
              </span>
              <div className="modal-confirm p-5">
                <div className="icon-box pb-4">
                  <img src={checkGIf} />
                </div>
                <h1 className="small-heading">Job Applied</h1>
              </div>
            </div>
          }
        /> */}
      </div>
    </>
  );
}
AOS.init();
