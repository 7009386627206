import Aos from "aos";
import React, { useEffect, useState } from "react";
import {
  platformDropData,
  platformDropData2,
} from "../../../../assets/customData/Data";
import Button from "../Button";
import { DropDown } from "../DropDown";
import { InputField } from "../InputField";
export function MessageDrop() {
  const initialValue = {
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
    plateformType: "",
    applicationType: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  //   const [textArea, setTextArea] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormValue({ ...formValue, [name]: value });
    setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log(formValue);
  };

  const handleDropDown = (name, value) => {
    // debugger;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };
  // console.log(formValue);
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValue));
    setIsSubmit(true);
    setFormValue(initialValue);
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(formValue);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const phoneNUmber =
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (!values.fullName) {
      errors.fullName = "Username is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else if (!phoneNUmber.test(values.phoneNumber)) {
      errors.phoneNumber = "This is not a valid phoneNumber";
    }

    return errors;
  };
  // console.log("length????????????", formValue.fullName.length);

  //   debugger;

  return (
    <>
      <div className="common-form">
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="row">
            <div
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <InputField
                type="text"
                placeholder="Full Name"
                name="fullName"
                onchange={handleChange}
                value={formValue?.fullName}
              />
              {formValue?.fullName?.length < 1 && (
                <p className="error-msg text-danger">{formErrors.fullName}</p>
              )}
            </div>
            <div
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <InputField
                type="email"
                placeholder="Email Address"
                name="email"
                onchange={handleChange}
                value={formValue?.email}
              />
              <p className="error-msg text-danger">{formErrors.email}</p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <InputField
                type="text"
                placeholder="Phone No"
                name="phoneNumber"
                onchange={handleChange}
                value={formValue?.phoneNumber}
                // onKeyPress={(e) => {
                //   if (!/(?<=^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                //     e.preventDefault();
                //   }
                // }}
              />
              {formValue?.phoneNumber?.length < 1 && (
                <p className="error-msg text-danger">
                  {formErrors.phoneNumber}
                </p>
              )}
            </div>
            <div
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <InputField
                type="text"
                placeholder="Party Date"
                name="partydate"
                // onchange={handleChange}
                // value={formValue?.email}
              />
            </div>
            {/* <div
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <InputField
                type="text"
                placeholder="Subject"
                name="subject"
                onchange={handleChange}
                value={formValue?.subject}
              />
              {formValue?.subject?.length < 1 && (
                <p className="error-msg text-danger">{formErrors.subject}</p>
              )}
            </div> */}
          </div>
          {/* <div className="row drop-row ">
            <div
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <DropDown
                name="plateformType"
                dropDownData={platformDropData}
                placeholder="Platform Type"
                onChange={handleDropDown}
                value={formValue.plateformType || "Platform Type"}
              />{" "}
              {formValue?.plateformType?.length < 1 && (
                <p className="error-msg text-danger">
                  {formErrors.plateformType}
                </p>
              )}
            </div>
            <div
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <DropDown
                name="applicationType"
                dropDownData={platformDropData2}
                placeholder="Application Type"
                onChange={handleDropDown}
                value={formValue.applicationType || "Application Type"}
              />
              {formValue?.applicationType?.length < 1 && (
                <p className="error-msg text-danger">
                  {formErrors.applicationType}
                </p>
              )}
            </div>
          </div> */}
          {/* <div className="row">
            <div
              className="col-12"
              data-aos="zoom-out"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            >
              <textarea
                name="message"
                id="msg"
                type="text"
                cols="30"
                rows="4"
                placeholder="Message"
                onChange={handleChange}
                value={formValue.message}
              />
              {formValue?.message?.length < 1 && (
                <p className="error-msg text-danger">{formErrors.message}</p>
              )}
            </div>
          </div> */}
          <div className="row">
            <div
              className="col-auto d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-duration="1500"
              data-aos-easing="ease-in-sine"
            >
              <button className="theme-btn">Submit</button>
              {Object.keys(formErrors).length === 0 && isSubmit && (
                <div className="text-success ms-4">Submited Successfully</div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
Aos.init();
