import React, { useEffect, useState } from "react";
import { jobTypes } from "../../../../assets/customData/Data";
import { DropDown } from "../DropDown";
import { InputField } from "../InputField";
import pdfIcon from "../../../../assets/images/pdf.svg";
import crossIcon from "../../../../assets/images/cross.svg";
import CommonModal from "../../modal/CommonModal";
import checkGIf from "../../../../assets/images/check.gif";

export function ApplyJob({ jobApplyClose, jobAppliedsetShow }) {
  const initialValue = {
    fullName: "",
    email: "",
    phoneNumber: "",
    experience: "",
    jobType: "",
    resume: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  //   const [textArea, setTextArea] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormValue({ ...formValue, [name]: value });
    if (name === "resume") {
      setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));
    } else {
      setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    console.log(formValue);
  };

  const handleDropDown = (name, value) => {
    // debugger;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };
  console.log(formValue);
  const AppliedJOb = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      //   console.log(formValue);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    let isValid = true;
    // // phone number regex   \

    // // phone number regex

    if (!values.fullName) {
      errors.fullName = "Username is required!";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is required!";
    }

    if (!values.email) {
      errors.email = "Email is required!";
    }
    // else if (!regex.test(values.email)) {
    //   errors.email = "This is not a valid email format!";
    // }
    if (!values.jobType) {
      errors.jobType = "Job Type is required!";
    }
    if (!values.experience) {
      errors.experience = "Experience is required!";
    }
    if (!values.resume) {
      errors.resume = "Resume is required!";
    }
    return errors;
  };

  //   debugger;

  return (
    <>
      <div className="common-form">
        {Object.keys(formErrors).length === 0 && isSubmit ? (
          <div className=" text-center">
            <div className=" p-5">
              <div className="icon-box pb-4">
                <img src={checkGIf} />
              </div>
              <h1 className="small-heading">Job Applied</h1>
            </div>
          </div>
        ) : (
          <form onSubmit={AppliedJOb} className="contact-form">
            <div className="row pb-5">
              <div className="col-xl-6">
                <InputField
                  type="text"
                  placeholder="Job Name"
                  name="jobTitle"
                  onchange={handleChange}
                  value="LEAD PROGRAMMER"
                />
              </div>
              <div className="col-xl-6">
                <InputField
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  onchange={handleChange}
                  value={formValue?.fullName}
                />
                {formValue?.fullName?.length < 1 && (
                  <p className="error-msg text-danger">{formErrors.fullName}</p>
                )}
              </div>
              <div className="col-xl-6">
                <InputField
                  type="tel"
                  placeholder="Phone No"
                  name="phoneNumber"
                  onchange={handleChange}
                  value={formValue?.phoneNumber}
                  // onKeyPress={(e) => {
                  //   if (!/(?<=^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {formValue?.phoneNumber?.length < 1 && (
                  <p className="error-msg text-danger">
                    {formErrors.phoneNumber}
                  </p>
                )}
              </div>
              <div className="col-xl-6">
                <InputField
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  onchange={handleChange}
                  value={formValue?.email}
                />
                <p className="error-msg text-danger">{formErrors.email}</p>
              </div>

              <div className="col-xl-6">
                <DropDown
                  name="jobType"
                  dropDownData={jobTypes}
                  placeholder="Please select job type"
                  onChange={handleDropDown}
                  value={formValue.jobType || "Please select job type"}
                />
                {formValue?.jobType?.length < 1 && (
                  <p className="error-msg text-danger">{formErrors.jobType}</p>
                )}
              </div>
              <div className="col-xl-6">
                <InputField
                  type="text"
                  placeholder="Experience (Min. 6 Months Reqd.)"
                  name="experience"
                  onchange={handleChange}
                  value={formValue?.experience}
                />
                {formValue?.experience?.length < 1 && (
                  <p className="error-msg text-danger">
                    {formErrors.experience}
                  </p>
                )}
              </div>
              <div className="col-xl-6 file-selector">
                <InputField
                  type="file"
                  id="resume"
                  placeholder="Upload Resume  "
                  name="resume"
                  onchange={handleChange}
                  accept=".pdf,.doc"
                  // value={formValue?.experience || "Upload Resume"}
                />

                {formValue?.resume?.length < 1 && (
                  <p className="error-msg text-danger">{formErrors.resume}</p>
                )}
              </div>

              {formValue?.resume?.name ? (
                <div className="col-md-12">
                  <div className="file-name">
                    <img src={pdfIcon} alt="file icon" />
                    <span>{formValue?.resume?.name}</span>
                    <img
                      src={crossIcon}
                      alt="cross"
                      className="pointer remove-file"
                      onClick={() => {
                        setFormValue((prev) => ({ ...prev, resume: "" }));
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row form-footer">
              <div className="col-auto d-flex align-items-center">
                <button
                  className="theme-btn"
                  // onClick={() => {
                  //   jobAppliedsetShow();
                  //   jobApplyClose();
                  // }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
