import Aos from "aos";
import React from "react";
import Button from "../form/Button";

export const Card = ({ commonCard }) => {
  return commonCard?.map((item, index) => {
    return (
      <div className="col-xl-6 mb-5 mb-xl-0 video-custom" key={index + 1}>
        <div className="card overflow-hidden">
          {item?.image ? (
            <video
              src={item.image}
              controls
              className="card-img-top"
              alt="nft-1"
              data-aos="zoom-out"
              data-aos-duration="1000"
              data-aos-easing="ease-in-sine"
            />
          ) : (
            ""
          )}

          <div className="card-body p-5">
            <h3
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-sine"
            >
              {item.heading}
            </h3>
            <p
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-easing="ease-in-sine"
            >
              {item.content}
            </p>
          </div>
        </div>
      </div>
    );
  });
};
Aos.init();
