// Our Games data
export const gamesTab = [
  {
    id: 1,
    title: "Mobile",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plann out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/game01.png",
    heading: "FUN WITH TIMES TABLE",
    subHeading: "Study | Mobile",
    content:
      "To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have",
    iosAppUrl: "https://www.apple.com/in/app-store/",
    playStoreUrl:
      "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
    // sliderData: [
    //   {
    //     slideImage: "images/game01.png",
    //     heading: "FUN WITH TIMES TABLE",
    //     subHeading: "Study | Mobile",
    //     content:
    //       "To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have",
    //     iosAppUrl: "https://www.apple.com/in/app-store/",
    //     playStoreUrl:
    //       "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
    //   },
    //   {
    //     slideImage: "images/game02.png",
    //     heading: "AR (AUGMENTED REALITY)",
    //     subHeading: "Adventure | Mobile",
    //     content:
    //       "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    //     iosAppUrl: "https://www.apple.com/in/app-store/",
    //     playStoreUrl:
    //       "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
    //   },
    //   {
    //     slideImage: "../images/game03.png",
    //     heading: "VR (VIRTUAL REALITY)",
    //     subHeading: "Adventure | Mobile",
    //     content:
    //       "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    //     iosAppUrl: "https://www.apple.com/in/app-store/",
    //     playStoreUrl:
    //       "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
    //   },
    //   {
    //     slideImage: "images/game04.png",
    //     heading: "WEB",
    //     subHeading: "Adventure | Web",
    //     content:
    //       "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    //   },
    //   {
    //     slideImage: "images/game05.png",
    //     heading: "UNITY",
    //     subHeading: "Adventure | Mobile",
    //     content:
    //       "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    //   },
    //   {
    //     slideImage: "images/game06.png",
    //     heading: "UNREAL",
    //     subHeading: "Adventure | Mobile",
    //     content:
    //       "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla… Read More",
    //   },
    // ],
  },
  {
    id: 2,
    title: "AR",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plann out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/game02.png",
    heading: "AR (AUGMENTED REALITY)",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    iosAppUrl: "https://www.apple.com/in/app-store/",
    playStoreUrl:
      "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
  },
  {
    id: 3,
    title: "VR",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plann out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "../images/game03.png",
    heading: "VR (VIRTUAL REALITY)",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    iosAppUrl: "https://www.apple.com/in/app-store/",
    playStoreUrl:
      "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
  },
  {
    id: 4,
    title: "Web",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plann out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/game04.png",
    heading: "WEB",
    subHeading: "Adventure | Web",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
  },
  {
    id: 5,
    title: "Unity",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plann out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/game05.png",
    heading: "UNITY",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
  },
  {
    id: 6,
    title: "Unreal",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plann out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/game06.png",
    heading: "UNREAL",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla… Read More",
  },
];
export const GamesData = [
  {
    id: 1,
    slideImage: "images/game01.png",
    heading: "FUN WITH TIMES TABLE",
    subHeading: "Study | Mobile",
    content:
      "To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have To get Mathematics on your Kids Finger tips, Xeemu Studios presents you with an amazing app Fun with time table to make this arduous task a lot of fun with target audience of 3 to 12 yrs of kids. We have",
    iosAppUrl: "https://www.apple.com/in/app-store/",
    playStoreUrl:
      "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
  },
  {
    id: 2,
    slideImage: "images/game02.png",
    heading: "AR (AUGMENTED REALITY)",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    iosAppUrl: "https://www.apple.com/in/app-store/",
    playStoreUrl:
      "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
  },
  {
    id: 3,
    slideImage: "../images/game03.png",
    heading: "VR (VIRTUAL REALITY)",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
    iosAppUrl: "https://www.apple.com/in/app-store/",
    playStoreUrl:
      "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509651_device_c&gclid=CjwKCAjwvNaYBhA3EiwACgndgrCrgxjCbg1vxCx6aytIJBWstrD1FVLeUO3vD21KsSz35CvhFD9RfRoCLsEQAvD_BwE&gclsrc=aw.ds",
  },
  {
    id: 4,
    slideImage: "images/game04.png",
    heading: "WEB",
    subHeading: "Adventure | Web",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
  },
  {
    id: 5,
    slideImage: "images/game05.png",
    heading: "UNITY",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
  },
  {
    id: 6,
    slideImage: "images/game06.png",
    heading: "UNREAL",
    subHeading: "Adventure | Mobile",
    content:
      "From Virtual or Augmented Reality, to desktop browsers and mobile devices, a metaverse can use a range of devices to take its users to a different reality where they can build relationships, invest in assets, visit pla",
  },
];
// Art games data
export const gamesArtTab = [
  {
    id: 7,
    title: "Concept Art",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",

    slideImage: "images/artGame01.png",
    heading: "GAME CONCEPT ART",
    heading01: "2D Design",
    heading02: "3D Design",
    content:
      "Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.",
    content01:
      "We focus on creating perfect 2D design based on well-thought-out concepts of characters, environments, and props. Our concept art agency",
    content02:
      "We focus on creating perfect 3D design based on well-thought-out concepts of characters, environments, and props. Our concept art agency",
  },
  {
    id: 8,
    title: "Character Design",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/artGame02.png",
    heading: "CHARACTER DESIGN",
    content:
      "Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games. Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.",
  },
  {
    id: 9,
    title: "Animation",
    heading: "Animation",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/artGame03.png",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
    content01:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
  },
  {
    id: 10,
    title: "Environment Design",
    heading: "Environment Design",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/artGame04.png",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
    content01:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
  },
  {
    id: 11,
    title: "Assets",
    heading: "ASSETS",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/artGame05.png",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
    content01:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
  },
  {
    id: 12,
    title: "UI/ UX",
    heading: "UI/UX DESIGN",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/artGame06.png",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
    content01:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
  },
  {
    id: 13,
    title: "MOTION GRAPHIC",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    heading: "MOTION GRAPHIC",
    demo: "Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget. Brainstorming initial scope, concept, design, define overall flow and functionality. Plan out entire project Architect and various iterations to ensure a Quality product finished within Time and Budget.",
    slideImage: "images/artGame07.png",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
    content01:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
  },
];
// export const gamesArtTabData = [
//   {
//     id: 1,
//     slideImage: "images/artGame01.png",
//     heading: "GAME CONCEPT ART",
//     heading01: "2D Design",
//     heading01: "3D Design",
//     content:
//       "Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.",
//     content01:
//       "We focus on creating perfect 2D design based on well-thought-out concepts of characters, environments, and props. Our concept art agency",
//     content02:
//       "We focus on creating perfect 3D design based on well-thought-out concepts of characters, environments, and props. Our concept art agency",
//   },
//   {
//     id: 2,
//     slideImage: "images/artGame02.png",
//     heading: "CHARACTER DESIGN",
//     content:
//       "Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games. Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.",
//   },
//   {
//     id: 3,
//     slideImage: "images/game01.png",
//     heading: "CHARACTER DESIGN",
//     content:
//       "Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games. Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.Our concept art outsourcing studio has extensive experience in providing art services that regularly contribute to the success of the industry’s most famous video games.",
//   },
// ];
// nft development
export const NftDevData = [
  {
    id: 1,
    // heading: "NFT DESIGN",
    image: "images/shisha1.mp4",
    // content:
    //   "NFT gaming platform development company offering state-of-the-art NFT gaming solutions for a unique gamingexperience. Our team keeps up with cutting edge technology and the latest trends to offer our customers products that stand out.",
  },
  {
    id: 2,
    // heading: "NFT GAME DEVELOPMENT",
    image: "images/shisha2.mp4",
    // content:
    //   "  NFT gaming platform development company offering state-of-the-art NFT gaming solutions for a unique gaming experience. Our team keeps up with cutting edge technology and the latest trends to offer our customers products that  stand out.",
  },
  {
    id: 3,
    // heading: "NFT GAME DEVELOPMENT",
    image: "images/shisha3.mp4",
    // content:
    //   "  NFT gaming platform development company offering state-of-the-art NFT gaming solutions for a unique gaming experience. Our team keeps up with cutting edge technology and the latest trends to offer our customers products that  stand out.",
  },
  {
    id: 4,
    // heading: "NFT GAME DEVELOPMENT",
    image: "images/shisha4.mp4",
    // content:
    //   "  NFT gaming platform development company offering state-of-the-art NFT gaming solutions for a unique gaming experience. Our team keeps up with cutting edge technology and the latest trends to offer our customers products that  stand out.",
  },
  {
    id: 5,
    // heading: "NFT GAME DEVELOPMENT",
    image: "images/shisha5.mp4",
    // content:
    //   "  NFT gaming platform development company offering state-of-the-art NFT gaming solutions for a unique gaming experience. Our team keeps up with cutting edge technology and the latest trends to offer our customers products that  stand out.",
  },
];
// out team data
export const multicards = [
  {
    id: 1,
    heading: "Vladimir M.",
    image: "images/client01.png",
    designation: "Lead Designer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
  {
    id: 2,
    heading: "Dimitri A.",
    image: "images/client02.png",
    designation: "Designer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
  {
    id: 3,
    heading: "Natalia L.",
    image: "images/client05.png",
    designation: "Game Artist",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
  {
    id: 4,
    heading: "Asimor R.",
    image: "images/client03.png",
    designation: "Developer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
  {
    id: 5,
    heading: "Eliza G.",
    image: "images/client04.png",
    designation: "Developer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
];
// gallary data
export const gallery = [
  {
    id: 1,
    image: "images/gall-2.jpeg",
  },
  {
    id: 2,
    image: "images/gall-2.jpeg",
  },
  {
    id: 3,
    image: "images/gall-2.jpeg",
  },
  {
    id: 4,
    image: "images/gall-4.jpg",
  },
  {
    id: 5,
    image: "images/gall-5.jpg",
  },
  {
    id: 6,
    image: "images/gall-6.jpg",
  },
];
//  hiring data
export const hiringSlides = [
  {
    is: 1,
    heading: "LEAD PROGRAMMER",
    btn_title: "VIEW DETAILS",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
  {
    is: 1,
    heading: "LEVEL DESIGNER",
    btn_title: "VIEW DETAILS",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
  {
    is: 1,
    heading: "AI ENGINEER",
    btn_title: "VIEW DETAILS",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown",
  },
];
// leave us a message
export const leaveMessage = [
  {
    id: 1,
    placeholder: "Full Name",
    name: "Name",
    type: "text",
  },
  {
    id: 2,
    placeholder: "Email Address",
    name: "email",
    type: "email",
  },
];
// plateform dropdown data
export const platformDropData = [
  {
    id: 1,
    dropdownTitle: "IOS Application",
  },
  {
    id: 2,
    dropdownTitle: "Android Application",
  },
  {
    id: 3,
    dropdownTitle: "Hybrid Application",
  },
  {
    id: 4,
    dropdownTitle: "Web Application",
  },
];
export const platformDropData2 = [
  {
    id: 1,
    dropdownTitle: "Gaming App",
  },
  {
    id: 2,
    dropdownTitle: "Education App ",
  },
  {
    id: 3,
    dropdownTitle: " Business App",
  },
  {
    id: 4,
    dropdownTitle: "Travel App ",
  },
];

// job types
export const jobTypes = [
  {
    id: "1",
    dropdownTitle: "Job 1",
  },
  {
    id: "2",
    dropdownTitle: "Job 2",
  },
  {
    id: "3",
    dropdownTitle: "Job 3",
  },
  {
    id: "4",
    dropdownTitle: "Job 4",
  },
];
