import Aos from "aos";
import React, { useState, useRef } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
// images
import logo from "../../assets/images/Group 249.png";
import newLogo from "../../assets/images/logo-me.png";
export default function Header({ setHideShow, hideShow, gotoServices }) {
  // sticky header
  const [headerClassName, setHeaderClassName] = useState("");
  const divRef = useRef(null);
  const handleScroll = (headerClassName) => {
    if (headerClassName !== "fade-in-header" && window.pageYOffset >= 50) {
      setHeaderClassName("fade-in-header");
    } else if (
      headerClassName === "fade-in-header" &&
      window.pageYOffset < 50
    ) {
      setHeaderClassName("");
    }
  };
  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);

  // end  sticky header
  const [headerData, setHeaderData] = useState([
    { id: 1, title: "ABOUT", url: "about", className: "" },
    // { id: 2, title: "GAMES", url: "games", className: "" },
    { id: 3, title: "Our Menu", url: "NFT", className: "" },
    { id: 4, title: "Gallery", url: "studio", className: "" },
    { id: 5, title: "Book Party", url: "contact", className: "" },
    // { id: 6, title: "TEAM", url: "team", className: "" },
    // { id: 7, title: "STUDIO", url: "studio", className: "" },
    // { id: 8, title: "CAREERS", url: "career", className: "" },
    {
      id: 9,
      title: "CONTACT",
      url: "contact",
      className: "btn theme-btn header-btn",
    },
  ]);
  // const headerData = [
  //   { id: 1, title: "ABOUT", url: "about" },
  //   { id: 2, title: "GAMES", url: "games" },
  //   { id: 3, title: "GAME ART", url: "gameArt" },
  //   { id: 4, title: "NFT", url: "NFT" },
  //   { id: 5, title: "METAVERSE", url: "metaverse" },
  //   { id: 6, title: "TEAM", url: "team" },
  //   { id: 7, title: "STUDIO", url: "studio" },
  //   { id: 8, title: "CAREERS", url: "career" },
  //   {
  //     id: 9,
  //     title: "CONTACT",
  //     url: "#contact",
  //     className: "btn theme-btn header-btn",
  //   },
  // ];

  // const [isActive, setActive] = useState("false");
  const ToggleClass = (data) => {
    // console.log(divRef);
    // divRef.current.classList.remove("show");
    // divRef.current.classList.add("hide");
    // debugger;
    setHeaderData((prev) =>
      prev?.map((itm) => {
        if (itm.id === data?.id) {
          itm.className = "active";
        } else {
          itm.className = "";
        }
        return itm;
      })
    );
  };

  console.log("headerData", headerData);
  return (
    <>
      <Navbar className={` fixed-top ${headerClassName}`} expand="xl">
        <Container>
          <Navbar.Brand href="#">
            <img
              src={newLogo}
              className="img-fluid"
              alt="Logo"
              data-aos="zoom-in"
              data-aos-duration="1200"
              data-aos-easing="ease-in-sine"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setHideShow(!hideShow)}
            // className="navbar-toggler collapsed"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            // ref={divRef}
          >
            <Nav className="ms-auto">
              <ul className="navbar-nav mb-2 mb-lg-0">
                {headerData.map((item, index) => {
                  return (
                    <li
                      className="nav-item"
                      key={index + 1}
                      onClick={() => {
                        // ToggleClass();
                        // removeClass();
                      }}
                      data-aos="zoom-in"
                      data-aos-duration="1200"
                      data-aos-easing="ease-in-sine"
                    >
                      <Nav.Link
                        to="/"
                        className={item.className}
                        id={`close-button-${index + 1}`}
                        onClick={() => {
                          gotoServices(item?.url, `close-button-${index + 1}`);
                          ToggleClass(item);
                        }}
                      >
                        {item?.title}
                      </Nav.Link>
                    </li>
                  );
                })}
              </ul>
            </Nav>
            {/* <Button className="header-btn" link="#contact" title={"CONTACT"} /> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
Aos.init();
