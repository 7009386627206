import React from "react";
import { Slide } from "react-slideshow-image";
import nextIcon from "../../../assets/images/white-next.svg";
import prevIcon from "../../../assets/images/prev-icon.svg";
import Button from "../form/Button";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Aos from "aos";

export const Multi_item_slider = ({
  slides,
  slidesToScroll,
  slidesToShow,
  isButton,
  indicators,
  onClick,
  // responsiveSettings,
  responsive,
  canSwipe,
}) => {
  const properties = {
    prevArrow: (
      <button>
        <img src={prevIcon} />
      </button>
    ),
    nextArrow: (
      <button>
        <img src={nextIcon} />
      </button>
    ),
  };
  return (
    <div>
      <Slide
        slidesToScroll={slidesToScroll}
        slidesToShow={slidesToShow}
        indicators={false}
        responsive={responsive}
        canSwipe={canSwipe}
        {...properties}
      >
        {slides?.map((item, index) => (
          <div
            key={index + 1}
            style={{
              margin: "15px 8px",
            }}
          >
            <div className="card">
              {item.image && (
                <img
                  src={item.image}
                  className="card-img-top"
                  alt="..."
                  data-aos="zoom-out"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-sine"
                />
              )}
              <div className="card-body text-center">
                <div className="user-cont">
                  <h2
                    className="sub-heading"
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-sine"
                  >
                    {item.heading}
                  </h2>
                  {item.designation && (
                    <p
                      className="common-text"
                      data-aos="zoom-out"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-sine"
                    >
                      {item.designation}
                    </p>
                  )}
                </div>
                <div
                  className={`card-text ${item.btn_title ? "common-text" : ""}`}
                >
                  <p>{item.content}</p>
                </div>

                {/* <Button className="header-btn" link="" title={"CONTACT"} /> */}
                {item.btn_title && (
                  <span
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-sine"
                  >
                    <Button
                      className="header-btn"
                      link=""
                      title={item.btn_title}
                      onClick={onClick}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};
Aos.init();
