import React from "react";
export function InputField({
  type,
  name,
  id,
  placeholder,
  className,
  value,
  maxLength,
  onchange,
}) {
  return (
    <div className="form-group position-relative">
      <div className="input-box">
        <input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          className={`text-input form-control ${className ? className : ""}`}
          value={value}
          onChange={onchange}
          maxLength={maxLength && maxLength}
        />
      </div>
    </div>
  );
}
