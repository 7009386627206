import React from "react";
import { Spinner } from "react-bootstrap";
import loader from "../../../assets/images/logo-me.png";
import loaderRight from "../../../assets/images/logo-right.png";
import loaderLeft from "../../../assets/images/logo-left.png";
import Aos from "aos";
export function Loader() {
  return (
    <>
      <div className="xeemu-loading">
        {/* <Spinner animation="grow" /> */}
        <div
          className="loader-circle"
          src={loader}
          alt="loader"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        ></div>
        <div
          className="loader-line-mask"
          src={loader}
          alt="loader"
          data-aos="zoom-in"
          data-aos-duration="1500"
          data-aos-easing="ease-in-sine"
        >
          <div
            className="loader-line"
            data-aos="zoom-in"
            data-aos-duration="1800"
            data-aos-easing="ease-in-sine"
          ></div>
        </div>
        <img
          src={loader}
          alt="loader"
          data-aos="zoom-in"
          data-aos-duration="500"
          data-aos-easing="ease-in-sine"
        />
        {/* <img src={loaderRight} alt="loader" className="loader-left" />
        <img src={loaderLeft} alt="loader" className="loader-right" /> */}
      </div>
    </>
  );
}
Aos.init();
