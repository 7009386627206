import React from "react";
import { Modal } from "react-bootstrap";

import crossIcon from "../../../assets/images/cross.svg";

export default function CommonModal({
  show,
  close,
  heading,
  body,
  className,
  footer,
}) {
  return (
    <Modal show={show} centered className={`${className ? className : ""} `}>
      {heading ? (
        <Modal.Header>
          <Modal.Title>{heading}</Modal.Title>
          <span className="close-btn float-end " role="button">
            <img src={crossIcon} alt="..." onClick={close} />
          </span>
        </Modal.Header>
      ) : (
        ""
      )}
      <Modal.Body>{body}</Modal.Body>
      {footer ? <Modal.Footer>{footer}</Modal.Footer> : ""}
    </Modal>
  );
}
