import React, { useState } from "react";
import facebook from "../../assets/images/facebook-icon.svg";
import twitter from "../../assets/images/twitter-icon.svg";
import googleplus from "../../assets/images/google-plus-icon.svg";
import linkedIn from "../../assets/images/linkedin-icon.svg";
import youTube from "../../assets/images/youtube-icon.svg";
import Aos from "aos";
export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="linebar-top"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="footer-content">
                <p
                  className="mb-0"
                  data-aos="zoom-out"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-sine"
                  data-aos-offset="0"
                  data-aos-delay="0"
                >
                  &copy; 2022 Mastani Shisha Lounge. All Rights Reserved.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-social">
                <ul>
                  <li
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-sine"
                    data-aos-offset="0"
                    data-aos-delay="0"
                  >
                    <a href="#">
                      <img src={facebook} alt="facebook-icon" />
                    </a>
                  </li>
                  <li
                    data-aos="zoom-out"
                    data-aos-duration="1200"
                    data-aos-easing="ease-in-sine"
                    data-aos-offset="0"
                    data-aos-delay="0"
                  >
                    <a href="#">
                      <img src={twitter} alt="twitter-icon" />
                    </a>
                  </li>
                  <li
                    data-aos="zoom-out"
                    data-aos-duration="1300"
                    data-aos-easing="ease-in-sine"
                    data-aos-offset="0"
                    data-aos-delay="0"
                  >
                    <a href="#">
                      <img src={googleplus} alt="google-plus-icon" />
                    </a>
                  </li>
                  <li
                    data-aos="zoom-out"
                    data-aos-duration="1400"
                    data-aos-easing="ease-in-sine"
                    data-aos-offset="0"
                    data-aos-delay="0"
                  >
                    <a href="#">
                      <img src={linkedIn} alt="linkedin-icon" />
                    </a>
                  </li>
                  <li
                    data-aos="zoom-out"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-sine"
                    data-aos-offset="0"
                    data-aos-delay="0"
                  >
                    <a href="#">
                      <img src={youTube} alt="youtube-icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
Aos.init();
